import BaseSetting, { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import Routes from './Routes';

// const [region] = useLocalStorage(constant.SELECTED_REGIONS);
// const regionName = region?.split(' ')[0];

/**
 * Contains web api urls
 * */

class WebApiRoutes extends Routes {
  getSelectedRegion() {
    this.selectRegion = localStorage.getItem('INT_STORAGE_SELECTED_REGIONS');

    return this.selectRegion?.split(' ')[0];
  }

  //
  // routes
  // endpoint defined here
  // format: [url_name]Path
  //
  todosPath(params = {}, format = '') {
    return this.createUrl('/todos', params, format);
  }

  submitFormPath(params = {}, format = '') {
    return this.createUrl('/api/v1/submit-data', params, format);
  }

  listingPath(params = {}, format = '') {
    return this.createUrl('/api/v1/list-data', params, format);
  }

  transferSubresellerValidatePath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/transfer-subreseller/validate`,
      params,
      format
    );
  }

  transferSubresellerPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/transfer-subreseller`,
      params,
      format
    );
  }

  transferSubscriptionValidatePath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/subscription-transfer/validate`,
      params,
      format
    );
  }

  transferSubscriptionPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/subscription-transfer`,
      params,
      format
    );
  }

  systemStatusPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/system-status/:statusType`,
      params,
      format
    );
  }

  switchSPATypePath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/switch-spa`, params, format);
  }

  SPACrendentialsPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/spa-credentials-detail`,
      params,
      format
    );
  }

  getRecreateBackupAdminsListPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/recreate-backup-admin`,
      params,
      format
    );
  }

  createRecreateBackupAdminsPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/recreate-backup-admin`,
      params,
      format
    );
  }

  getRunLicenseCheckerListPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/run-license-checker`,
      params,
      format
    );
  }

  createRunLicenseCheckerPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/run-license-checker`,
      params,
      format
    );
  }

  createRunAutodiscoverPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/m365-credentials/run-autodiscover`,
      params,
      format
    );
  }

  invalidateServiceTokenPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/m365-credentials/invalidate-service-token`,
      params,
      format
    );
  }

  createRunBackupPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/run-backup`, params, format);
  }

  createChangeBackupAdminsPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/change-backup-admins`,
      params,
      format
    );
  }

  tenantDetailPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/m365-credentials/:credentialId`,
      params,
      format
    );
  }

  validateChangeBackupAdminPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/change-backup-admins/validate`,
      params,
      format
    );
  }

  getPartnerDetailPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/partners/:id`, params, format);
  }

  getPurgeM365TenantsListPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/purge-o365-credential`,
      params,
      format
    );
  }

  getChangeBackupAdminsPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/change-backup-admins`,
      params,
      format
    );
  }

  gwsTenantDetailPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/gws-credentials/:credentialId`,
      params,
      format
    );
  }

  createPurgeM365TenantPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/purge-o365-credential`,
      params,
      format
    );
  }

  getPurgeGWSTenantsListPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/purge-gws-credential`,
      params,
      format
    );
  }

  createPurgeGWSTenantPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/purge-gws-credential`,
      params,
      format
    );
  }

  userBackupListPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/user-backups`, params, format);
  }

  exportUserBackupPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/user-backups-export`,
      params,
      format
    );
  }

  userBackupDetailPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/user-backup-detail`,
      params,
      format
    );
  }

  lastBackupInfoPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/last-backup-info`, params, format);
  }

  backupStatusListPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/backup-statuses`, params, format);
  }

  getGwsUsersTenantPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/gws-credentials/users`,
      params,
      format
    );
  }

  getGwsSharedDrivesTenantPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/gws-credentials/sd-drives`,
      params,
      format
    );
  }

  toggleEnforceSSOPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/toggle-m365-sso`, params, format);
  }

  validateToggleEnforceSSOPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/toggle-m365-sso/validate`,
      params,
      format
    );
  }

  getToolsRequestHistoryPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/tools-request-history`,
      params,
      format
    );
  }

  getToolRequestDetails(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/tool-request-details`,
      params,
      format
    );
  }

  getServiceTypes(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/service-types`, params, format);
  }

  createTenantSizeReportPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/tenant-size-report`,
      params,
      format
    );
  }

  getExportTenantSizeReportPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/tenant-size-export`,
      params,
      format
    );
  }

  createUserDiscrepancyReportPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/user-discrepancy-report`,
      params,
      format
    );
  }

  getExportUserDiscrepancyReportPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/user-discrepancy-export`,
      params,
      format
    );
  }

  getInvoicesListPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/invoices`, params, format);
  }

  runXeroSyncPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/xero-sync`, params, format);
  }

  checkXeroSyncRunningPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/xero-sync/is-running`,
      params,
      format
    );
  }

  createUserBackupReportPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/user-backup-report`,
      params,
      format
    );
  }

  getExportUserBackupReportPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/user-backup-export`,
      params,
      format
    );
  }

  getOwnerListPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/owner-list`, params, format);
  }

  getChanneListPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/channel-list`, params, format);
  }
}

const ApiRoutes = new WebApiRoutes(BaseSetting.graphConfig.internalGateWayHost);

export const todosPath = ApiRoutes.todosPath();
export const getRecreateBackupAdminsAPI = ApiRoutes.getRecreateBackupAdminsListPath();
export const createRecreateBackupAdminsAPI = ApiRoutes.createRecreateBackupAdminsPath();
export const changeBackupAdminAPI = ApiRoutes.createChangeBackupAdminsPath();

export default ApiRoutes;
