import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken) => {
  return new ServiceClientFactory({ baseUrl: WebApiRoutes.getBaseUrl(), accessToken }).create();
};

const REGEX_ID = /^\d+$/;
const REGEX_EMAIL =
  // eslint-disable-next-line prettier/prettier, no-useless-escape
  /^(([^<>[\]\\.,;:\s@\"]+(\.[^<>[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const filterIdOrEmail = (string) => {
  if (REGEX_ID.test(string)) return [string, null, null];
  if (REGEX_EMAIL.test(string)) return [null, string, null];

  return [null, null, string];
};

const UserBackupService = {
  userBackupListAPI: (paramsList = {}) => {
    const {
      accessToken,
      reset,
      pageNumber,
      pageSize,
      resellerId,
      organizationOwnerId,
      backupType,
      filters,
    } = paramsList;
    const [id, email, otherText] = filterIdOrEmail(filters.searchById);
    const params = {
      'search-by-id': id || 0,
      'full-text-search': otherText || '',
      filters: [
        {
          field: 'filter_status',
          value: filters.status || '',
        },
        {
          field: 'filter_m365Id',
          value: filters.m365Id || '',
        },
        {
          field: 'filter_tenantId',
          value: filters.tenantId || '',
        },
        {
          field: 'filter_hasCct',
          value: filters.hasCct || '',
        },
        {
          field: 'filter_completedSetup',
          value: filters.completedSetup || '',
        },
        {
          field: 'filter_protocol',
          value: filters.protocol || '',
        },
        {
          field: 'filter_email',
          value: email || '',
        },
        {
          field: 'filter_isActive',
          value: filters.isActive || '',
        },
        {
          field: 'filter_hasGroup',
          value: filters.hasGroup || '',
        },
        {
          field: 'filter_deleted',
          value: filters.deleted || '',
        },
      ],
      'order-by': filters.orderBy || 'id desc',
    };

    const response = webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.userBackupListPath({
        pageNumber,
        pageSize,
        resellerId,
        organizationOwnerId,
        backupType,
      }),
      parameters: params,
    });

    return response;
  },

  exportUserBackupAPI: (paramsList = {}) => {
    const {
      accessToken,
      reset,
      pageNumber,
      pageSize,
      resellerId,
      organizationOwnerId,
      backupType,
      filters,
    } = paramsList;
    const [id, email, otherText] = filterIdOrEmail(filters.searchById);
    const params = {
      'search-by-id': id || 0,
      'full-text-search': otherText || '',
      filters: [
        {
          field: 'filter_status',
          value: filters.status || '',
        },
        {
          field: 'filter_m365Id',
          value: filters.m365Id || '',
        },
        {
          field: 'filter_tenantId',
          value: filters.tenantId || '',
        },
        {
          field: 'filter_hasCct',
          value: filters.hasCct || '',
        },
        {
          field: 'filter_completedSetup',
          value: filters.completedSetup || '',
        },
        {
          field: 'filter_protocol',
          value: filters.protocol || '',
        },
        {
          field: 'filter_email',
          value: email || '',
        },
        {
          field: 'filter_isActive',
          value: filters.isActive || '',
        },
        {
          field: 'filter_hasGroup',
          value: filters.hasGroup || '',
        },
      ],
      'order-by': filters.orderBy || 'id desc',
    };

    const response = webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.exportUserBackupPath({
        pageNumber,
        pageSize,
        resellerId,
        organizationOwnerId,
        backupType,
      }),
      parameters: params,
    });

    return response;
  },

  userBackupDetailAPI: (paramsList = {}) => {
    const { accessToken, reset, userBackupId, backupType } = paramsList;
    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.userBackupDetailPath({ userBackupId, backupType }),
    });
  },
  lastBackupInfoAPI: (paramsList = {}) => {
    const { accessToken, reset, backupId, backupType } = paramsList;
    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.lastBackupInfoPath({ backupId, backupType }),
    });
  },
  runBackupApi: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.createRunBackupPath(),
      parameters,
    });
  },
  backupStatusListAPI: (paramsList = {}) => {
    const {
      accessToken,
      reset,
      pageNumber,
      pageSize,
      resellerId,
      OrganizationOwnerId,
      backupType,
      filters,
    } = paramsList;
    const params = {
      filters: [
        {
          field: 'filter_status',
          value: filters.status || 'all',
        },
        {
          field: 'filter_startDate',
          value: filters.startDate,
        },
        {
          field: 'filter_endDate',
          value: filters.endDate,
        },
      ],
      'order-by': filters.orderBy || 'id',
    };

    const response = webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.backupStatusListPath({
        pageNumber,
        pageSize,
        resellerId,
        OrganizationOwnerId,
        backupType,
      }),
      parameters: params,
    });

    return response;
  },

  userBackupReportAPI: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.createUserBackupReportPath(),
      parameters,
    });
  },

  exportUserBackupReportAPI: (paramsList) => {
    const { accessToken, reset, workerId } = paramsList;
    const response = webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.getExportUserBackupReportPath({ workerId }),
      config: { responseType: 'arraybuffer' },
    });

    return response;
  },
};

export default UserBackupService;
