/* eslint-disable react/no-array-index-key */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { QboTypography, QboCheckbox, QboDropdown, QboLink } from '@ui/Components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Skeleton from '@mui/material/Skeleton';
import { visuallyHidden } from '@mui/utils';
import useCheckboxTable from '@pages/sharedHooks/useCheckboxTable';
import QboKebabMenu from './QboKebabMenu';

// HeaderObject
// eg:
//
// const header = [
//   {
//     id: 1,
//     columnName: 'objectName',
//     numeric: false,
//     label: 'Object Name',
//     spaceOnly: false,
//     size: 'default',
//     onClickCell: () => {}
//   },
// ];

// RowsObject
// eg:
//
// const rows = [
//   {
//     id: 1,
//     objectName: <div>test</div>,
//     isLink: true,
//     componenet: 'th',
//     label: 'Object Name',
//   },
// ];

function EnhancedTableHead({
  header,
  classNameHeaderStyle,
  order,
  orderBy,
  onRequestSort,
  loading,
  listSortOptions,
  selectedDropdown,
  onDropdownSelect,
  listId,
  withCheckboxSelection,
  withMinimumCheckbox,
  menuActions,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: '#c2e2ef' }}>
      <TableRow>
        {withCheckboxSelection && (
          <TableCell
            align="center"
            padding="none"
            sortDirection={false}
            className="QboTable__cell-size-xxs">
            <QboDropdown
              menuItems={listSortOptions}
              onClickItem={(selected) => {
                onDropdownSelect(selected, listId);
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              leftIcon={selectedDropdown?.icon}
              className={`QboTable__dropdown-checkbox ${
                withMinimumCheckbox ? 'QboTable__dropdown-checkbox__small' : ''
              }`}
              buttonClassName={`QboTable__dropdown-button-${selectedDropdown?.color}`}
            />
          </TableCell>
        )}
        {menuActions && (
          <TableCell
            align="left"
            padding="none"
            sortDirection={false}
            className="QboTable__cell-size-xxs">
            <div
              className={`QboTable__head-label ${classNameHeaderStyle} QboTable__head-label-no-divider`}>
              <QboTypography component="span" sx={{ whiteSpace: 'normal' }}>
                Actions
              </QboTypography>
            </div>
          </TableCell>
        )}
        {header.map((headCell, index) => (
          <TableCell
            key={`HeadCell-${index}`}
            align={headCell.align}
            padding="none"
            sortDirection={orderBy === headCell.id ? order : false}
            className={`QboTable__cell-size-${headCell.size} ${
              headCell.withColor ? 'QboTable__cell-with-color' : ''
            } ${headCell.thinPaddingLeft ? 'QboTable__cell-with-thin-pl' : ''} ${
              headCell.noPadding ? 'QboTable__cell-no-padding' : ''
            } `}>
            <div
              className={`QboTable__head-label ${classNameHeaderStyle} QboTable__head-label-${
                headCell.Divider ? '' : 'no-divider'
              }`}>
              {headCell.orderable && (
                <TableSortLabel
                  disabled={loading}
                  active={orderBy === headCell.columnName}
                  direction={orderBy === headCell.columnName ? order : 'asc'}
                  onClick={createSortHandler(headCell.columnName)}>
                  {headCell.label}
                  {orderBy === headCell.columnName ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}

              {!headCell.orderable && (
                <QboTypography component="span" sx={{ whiteSpace: 'normal' }}>
                  {headCell.label}
                </QboTypography>
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classNameHeaderStyle: PropTypes.string,
  header: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  listSortOptions: PropTypes.array,
  selectedDropdown: PropTypes.object,
  onDropdownSelect: PropTypes.func,
  listId: PropTypes.array,
  withCheckboxSelection: PropTypes.bool,
  withMinimumCheckbox: PropTypes.bool,
  menuActions: PropTypes.func,
};

EnhancedTableHead.defaultProps = {
  classNameHeaderStyle: '',
  loading: false,
  listSortOptions: [],
  selectedDropdown: {},
  onDropdownSelect: () => {},
  listId: [],
  withCheckboxSelection: false,
  withMinimumCheckbox: false,
  menuActions: null,
};
export default function QboSimpleDataTable({
  classNameHeaderStyle,
  minWidth,
  sx,
  header,
  rows,
  onClickRow,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  loading,
  stickyHeader,
  sxTableContainer,
  fullBorder,
  className,
  selected,
  setSelected,
  listSortOptions,
  selectedDropdown,
  onDropdownSelect,
  withCheckboxSelection,
  withMinimumCheckbox,
  menuActions,
}) {
  // console.log('🚀 ~ file: QboSimpleDataTable.jsx:172 ~ rows:', rows);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const { selectAllConst } = useCheckboxTable();

  const widthSkeleton = (index) => {
    if (index % 3 === 0) return '72%';
    if (index % 2 === 0) return '64%';

    return '84%';
  };

  const renderCellContent = (row, h) => {
    if (h.onClickCell) {
      return (
        <QboLink
          variant="div"
          wrap
          sx={{
            wordWrap: 'break-word',
            whiteSpace: 'normal',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': '5',
            '-webkit-box-orient': 'vertical',
          }}
          onClick={() => h.onClickCell(row)}>
          {row[h.columnName]?.toString()}
        </QboLink>
      );
    }
    return (
      <QboTypography
        component="div"
        wrap
        sx={{
          wordWrap: 'break-word',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          display: '-webkit-box',
          '-webkit-line-clamp': '5',
          '-webkit-box-orient': 'vertical',
        }}>
        {row[h.columnName]?.toString()}
      </QboTypography>
    );
  };

  /* eslint-disable no-else-return */
  const renderRows = () => {
    if (loading) {
      const loadingRows = rows.length > 3 ? rows : [...Array(5).keys()];
      return loadingRows.map((row, index) => (
        <TableRow tabIndex={-1} key={`LoadingRow-${index}`}>
          {withCheckboxSelection && (
            <TableCell key={`LoadingCell-${index}`} align="left">
              <Skeleton variant="text" height={22} width="100%" />
            </TableCell>
          )}
          {menuActions && (
            <TableCell key={`LoadingCell-${index}`} align="left">
              <Skeleton variant="text" height={22} width="100%" />
            </TableCell>
          )}
          {header.map((head, headerIndex) => (
            <TableCell
              key={`LoadingCell-${headerIndex}`}
              align={head.align || 'left'}
              className={`QboTable__cell-size-${head.size} ${
                head.withColor ? 'QboTable__cell-with-color' : ''
              } ${head.thinPaddingLeft ? 'QboTable__cell-with-thin-pl' : ''} ${
                head.noPadding ? 'QboTable__cell-no-padding' : ''
              } `}>
              <Skeleton
                variant="text"
                height={22}
                width={head.size === 'icon' ? '100%' : widthSkeleton(index)}
              />
            </TableCell>
          ))}
        </TableRow>
      ));
    } else {
      return rows.map((row, index) => {
        const labelId = `enhanced-table-checkbox-${index}`;
        const isRowChecked =
          (selected.includes(selectAllConst) && !selected.includes(row.id)) ||
          (!selected.includes(selectAllConst) && selected.includes(row.id));

        return (
          <TableRow
            hover
            tabIndex={-2}
            key={`ContentRow-${index}`}
            className={`${row.isLink ? 'QboTableRow__linking' : ''} QboTableCell__height-size-${
              row.heightSize
            } ${row.active ? 'QboTableRow__active' : ''}
            ${row.isLink && row.active ? 'QboTableRow__active-link' : ''} ${
              !(row.isLink && row.active) && isRowChecked && !row.summaryRow
                ? 'QboTableRow__selected'
                : ''
            }`}>
            {withCheckboxSelection && !(row.isLink && row.active) && (
              <TableCell
                key={`ContentCell-${labelId}`}
                component="td"
                id={labelId}
                align="left"
                scope=""
                colSpan={null}>
                {!(row.colspan > 0) && (
                  <QboCheckbox checked={isRowChecked} onChange={() => setSelected(row.id)} />
                )}
              </TableCell>
            )}
            {menuActions && !(row.isLink && row.active) && (
              <TableCell
                key={`ContentCell-${labelId}`}
                component="td"
                id={labelId}
                align="left"
                scope=""
                colSpan={null}>
                {!(row.colspan > 0) && <QboKebabMenu menuActions={menuActions} row={row} />}
              </TableCell>
            )}
            {header.map((h, hIndex) => {
              if (row[h.columnName] === '{{colspan}}') return null;

              return (
                <TableCell
                  key={`ContentCell-${hIndex}`}
                  component={row.component || h.component || 'td'}
                  onClick={() => {
                    onClickRow(row);
                  }}
                  id={labelId}
                  align={h.align || 'left'}
                  scope={row.component === 'th' ? 'row' : ''}
                  colSpan={h.allowColspan && row.colspan ? row.colspan : null}
                  className={`${h.withColor ? 'QboTable__cell-with-color' : ''}
                  ${h.thinPaddingLeft ? 'QboTable__cell-with-thin-pl' : ''} ${
                    h.noPadding ? 'QboTable__cell-no-padding' : ''
                  }
                  QboTable__cell-size-${h.size}`}>
                  {renderCellContent(row, h)}
                </TableCell>
              );
            })}
          </TableRow>
        );
      });
    }
  };

  return (
    <div
      className={`QboTable__wrapper ${
        fullBorder ? 'QboTable__wrapper-full-border' : ''
      } ${className}`}
      // eslint-disable-next-line react/no-unknown-property
      sx={sx}>
      <TableContainer sx={{ ...sxTableContainer }}>
        <Table
          stickyHeader={stickyHeader}
          sx={{ minWidth: minWidth ? 750 : 0 }}
          aria-labelledby="tableTitle">
          <EnhancedTableHead
            withMinimumCheckbox={withMinimumCheckbox}
            classNameHeaderStyle={classNameHeaderStyle}
            header={header}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            loading={loading}
            withCheckboxSelection={withCheckboxSelection}
            menuActions={menuActions}
            listSortOptions={listSortOptions}
            selectedDropdown={selectedDropdown}
            onDropdownSelect={onDropdownSelect}
            listId={rows.map((x) => x.id).filter((x) => x !== undefined)}
          />
          <TableBody>{renderRows()}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

QboSimpleDataTable.propTypes = {
  classNameHeaderStyle: PropTypes.string,
  minWidth: PropTypes.bool,
  sx: PropTypes.object,
  header: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  onClickRow: PropTypes.func,
  order: PropTypes.string,
  setOrder: PropTypes.func,
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  loading: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  sxTableContainer: PropTypes.object,
  fullBorder: PropTypes.bool,
  className: PropTypes.string,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  listSortOptions: PropTypes.array,
  selectedDropdown: PropTypes.object,
  onDropdownSelect: PropTypes.func,
  withCheckboxSelection: PropTypes.bool,
  withMinimumCheckbox: PropTypes.bool,
  menuActions: PropTypes.func,
};

QboSimpleDataTable.defaultProps = {
  classNameHeaderStyle: '',
  minWidth: true,
  sx: null,
  header: [],
  rows: [],
  onClickRow: () => {},
  order: 'asc',
  setOrder: () => {},
  orderBy: '',
  setOrderBy: () => {},
  loading: false,
  stickyHeader: false,
  sxTableContainer: {},
  fullBorder: false,
  className: '',
  selected: [],
  setSelected: () => {},
  listSortOptions: [],
  selectedDropdown: {},
  onDropdownSelect: () => {},
  withCheckboxSelection: false,
  withMinimumCheckbox: false,
  menuActions: null,
};
