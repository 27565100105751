import TodosService from './TodosService';
import FormService from './FormService';
import ListingService from './ListingService';
import TransferSubresellerService from './TransferSubresellerService';
import TransferSubscriptionService from './TransferSubscriptionService';
import SystemStatusService from './SystemStatusService';
import ClientTokenService from './ClientTokenService';
import MailBoxService from './MailBoxService';
import SwitchSPATypeService from './SwitchSPATypeService';
import TenantService from './TenantService';
import PartnerService from './PartnerService';
import UserBackupService from './UserBackupService';
import ToolsRequestHistoryService from './ToolsRequestHistoryService';
import UpdateEnforceSSOService from './UpdateEnforceSSOService';
import DmeSettingsService from './DmeSettingsService';

// eslint-disable-next-line import/prefer-default-export
export const { fetchTodosApi } = TodosService;
export const { submitFormApi } = FormService;
export const { fetchListingApi } = ListingService;
export const { transferSubresellerValidateApi, transferSubresellerApi } =
  TransferSubresellerService;
export const { transferSubscriptionValidateApi, transferSubscriptionApi } =
  TransferSubscriptionService;
export const { fetchSystemStatusServiceApi } = SystemStatusService;
export const { authenticateAPI, refreshTokenAPI, getRegionsAPI } = ClientTokenService;
export const { switchSPACommandListApi, switchSPACommandApi, credentilasSPAinfoApi } =
  SwitchSPATypeService;
export const {
  runBackupApi,
  userBackupListAPI,
  exportUserBackupAPI,
  userBackupDetailAPI,
  lastBackupInfoAPI,
  backupStatusListAPI,
  userBackupReportAPI,
  exportUserBackupReportAPI,
} = UserBackupService;
export const {
  getRecreateBackupAdminsAPI,
  recreateBackupAdminsAPI,
  runLicenseCheckerAPI,
  runAutodiscoverAPI,
  invalidateServiceTokenAPI,
  tenantDetailAPI,
  getPurgeM365TenantsAPI,
  purgeM365TenantAPI,
  gwsTenantDetailAPI,
  purgeGWSTenantAPI,
  getPurgeGWSTenantsAPI,
  getChangeBackupAdminsAPI,
  changeBackupAdminAPI,
  validateChangeBackupAdminAPI,
  getGwsUsersTenantAPI,
  getGwsSharedDrivesTenantAPI,
  tenantSizeReportAPI,
  exportTenantSizeReportAPI,
  userDiscrepancyReportAPI,
  exportUserDiscrepancyReportAPI,
} = TenantService;
export const { getPartnerDetailAPI } = PartnerService;
export const {
  createMailBoxAPI,
  createMailBoxChangeRequestAPI,
  createMailBoxSyncAPI,
  createMailBoxSyncRequestAPI,
  getExcludedMailboxesAPI,
  createExcludedMailboxesAPI,
  getAutoDiscoveryAPI,
  createAutoDiscoveryAPI,
  getDataIngestionAPI,
  getDetailsOfDataIngestionAPI,
  createDataIngestionAPI,
  getFeatureFlagAPI,
  getMailboxBulkPurgeAPI,
  createBulkPurgeRequestAPI,
  getMailboxBulkPurgeListAPI,
  getBulkPurgeMailStatusAPI,
  getDomainBackupsAPI,
  getMailboxChangeDetailAPI,
  getMailboxChangeStatusAPI,
  createDomainBackupsAPI,
  getTransferSubresellerAPI,
  getTransferSubscriptionAPI,
  getTransferSubscriptionInitiateAPI,
  createTransferSubscriptionInitiateAPI,
  getIngestionReportListAPI,
  getIngestionReportListItemAPI,
  createDataIngestionJobAPI,
  reRunDataIngestionListItemAPI,
  getIngestionFailedEmlAPI,
  getSeatUsageReportsAPI,
  getSeatUsageReportsSummaryAPI,
  exportSeatUsageReportAPI,
  removeDataIngestionItemsAPI,
  submitPurgeExternalAccountAPI,
  getPurgeExternalAccountAPI,
  submitPurgeSharedDriveAPI,
  getPurgeSharedDriveAPI,
  getTransferPartnerAPI,
  submitTransferPartnerAPI,
  validateTransferPartnerAPI,
  getPartnersListAPI,
  getOrganizationsListAPI,
  exportOrganizationsAPI,
  getPlansListAPI,
  submitSeatCountChangeAPI,
  getSeatCountChangeAPI,
  getUsersListAPI,
  getOrganizationUsersListAPI,
  getSelectedResellerConfigsAPI,
  getResellerNotificationsConfigAPI,
  getMonthlyBackupReportsAPI,
  exportMonthlyBackupReportAPI,
  getTenantAuthErrorsAPI,
  createTenantAuthErrorsAPI,
  getIngestionsInProgressAPI,
  getIngestionsQueuedAPI,
  getOrganizationInformationAPI,
  getPartnersFilterListAPI,
  getM365TenantAPI,
  exportM365TenantAPI,
  getGWSTenantAPI,
  getM365TenantDetailsAPI,
  getGWSTenantDetailsAPI,
  getHEXTenantAPI,
  getHEXTenantDetailsAPI,
  getEntraIdOrganizationAPI,
  getEntraIdTenantAPI,
  getOrganizationFilterListAPI,
  getParentFilterListAPI,
  getSwitchCredentialRequestAPI,
  getSwitchCredentialStatusAPI,
  getSwitchCredentialItemsAPI,
  createSwitchCredentialAPI,
  getM365UsersTenantAPI,
  getM365SitesTenantAPI,
  deleteDataIngestionJobAPI,
  bulkCreateOrganizationApi,
  getBulkCreateOrganizationTemplateApi,
  getBulkCreateOrganizationItemsApi,
  bulkCreateSubResellerApi,
  getBulkCreateSubResellerTemplateApi,
  getBulkCreateSubResellerItemsApi,
  getPlansFilterListAPI,
  updateSpecialPricingAPI,
} = MailBoxService;
export const { createToggleEnforceSSOApi, validateToggleEnforceSSOApi } = UpdateEnforceSSOService;
export const { getToolsRequestHistoryApi, getToolRequestDetailsApi, getServiceTypesApi } =
  ToolsRequestHistoryService;
export const { getOwnerListApi, getChannelListApi } = DmeSettingsService;
